
button, .button {
    --button-bg-color: var(--primary-color);
    --button-bg-color-interactive: var(--secondary-color);
    --button-text-color: Var(--light-gray-100);
    --button-text-color-interactive: var(--default-text-color);
    --button-border-color: var(--primary-color);
    --button-border-color-interactive: var(--secondary-color);

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: var(--button-text-color);
    gap: 0.5em;
    padding: 1rem;
    margin: unset;
    border: .125rem solid var(--button-border-color);
    border-radius: .25rem;
    background-color: var(--button-bg-color);
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, color .15s ease-in-out;


    &:hover {
        color: var(--button-text-color-interactive);
        border-color: var(--button-border-color-interactive);
        background-color: var(--button-bg-color-interactive);

        &:not([disabled]) {
            cursor: pointer;
        }
    }

    &:focus, &:focus-visible {
        outline-width: .125rem;
        outline-style: solid;
        outline-color: var(--button-border-color-interactive);
        outline-offset: .125rem;
        color: var(--button-text-color-interactive);
        border-color: var(--button-border-color-interactive);
        background-color: var(--button-bg-color-interactive);
    }

    &.contest {
        display: block;
    }

    &.success {
        --button-border-color: var(--success-color);
        --button-bg-color: var(--success-color);

        &:hover, &:focus-visible {
            --button-border-color-interactive: var(--success-color);
            --button-bg-color-interactive: var(--success-color);
        }
    }

    &.disabled, &[disabled] {
        cursor: not-allowed;
        background-color: var(--medium-gray-400);
        border-color: var(--medium-gray-400);
        color: var(--dark-gray-900);

        &:hover, &:focus-visible {
            cursor: not-allowed;
            background-color: var(--medium-gray-400);
            border-color: var(--medium-gray-400);
            color: var(--light-gray-800);
        }
    }
}
