[type='checkbox'] {
    margin: 0;

    & ~ label {
        margin: 0;
    }
}

.checkbox {
    & > label {
        display: block;
        vertical-align: middle;
        position: relative;
        padding: .2rem 0 0 1.75rem; // padding-left to offset label (checkbox/radio width + .5rem of "margin")
        min-height: 1.25rem;
        line-height: 1.25;

        &::before, &::after {
            content: '';
            box-sizing: border-box;
            display: inline-block;
            position: absolute;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            margin: 0;
            border: 1px solid transparent;
            left: 0;
            top: 0;
        }

        &::before {
            border-radius: .25rem;
            background-color: rgba(0 0 0 / .05);
            transition:
                border 0.15s ease-in-out,
                color 0.15s ease-in-out;
        }

        &::after {
            display: grid;
            place-content: center;
            font-size: .875rem;
            color: var(--white);
            transform: scale(0, 0);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }

    input[type="checkbox"] {
        box-sizing: border-box;
        display: inline-block;
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        margin: 0;
        border: 1px solid transparent;
        left: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        &:hover ~ label::before {
            border-color: var(--dark-gray-100);
        }

        &:focus ~ label::before {
            outline: none;
            border-color: var(--info-color);
            box-shadow: 0 0 1rem var(--info-color);
            background-color: rgba(0 0 0 / .05);
        }

        &.is-invalid-input ~ label::before {
            border-color: var(--error-color);
        }

        &:checked {
            & ~ label::before {
                background-color: var(--info-color);
            }

            & ~ label::after {
                content: '\f00c';
                font-family: var(--icon-family-100);
                font-weight: var(--font-weight-900);
                transform: scale(1, 1);
            }
        }

        &:indeterminate {
            & ~ label::after {
                display: block;
                content: "";
                width: 10px;
                height: 3px;
                border-radius: 2px;
                margin-left: -16.5px;
                margin-top: 7px;
            }
        }

        &:disabled,
        &.disabled,
        &[disabled] {
            cursor: not-allowed;
            border-color: transparent;

            & > label {
                color: var(--dark-gray-100);
            }

            &:not(:checked) {
                & ~ label {
                    opacity: 1;
                    color: var(--dark-gray-100);

                    &::before {
                        border-color: transparent;
                        background-color: rgba(0 0 0 / .05);
                        cursor: not-allowed;
                    }

                    &::after {
                        content: 'Disabled';
                        font-family: var(--font-family-100);
                        font-weight: var(--font-weight-400);
                        font-size: var(--fs-tiny);
                        color: var(--medium-gray-100);
                        top: -.5rem;
                        left: 1.625rem;
                        width: auto;
                        height: auto;
                    }
                }
            }

            &:checked {
                & ~ label::before {
                    background-color: var(--light-blue-900);
                }

                & ~ label::after {
                    color: var(--black);
                }
            }
        }
    }

    &.has-tip {
        display: block;
        border: none;
    }

    .form-error {
        border-radius: .25rem;
        padding: .25rem .5rem;
        margin-top: .25rem;
    }
}
