
div[itemscope] .row {
    max-width: 73.5rem;
}

.row.video {
    max-width: 74rem;
    margin: 0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        height: 80%;
    }
}

.video-padding {
    padding: 2rem 0;
}

.videoWrapper {
    position: relative;
    padding-bottom: 54%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

div[ng-show=videoPresent],
div[ng-show=video2Present] {
    max-width: 50rem;
}
