
p {
    line-height: 1.6;
    color: var(--default-text-color);
}

sup, .sup {
    vertical-align: top;
    display: inline-block;
    line-height: 3rem;
    color: var(--light-gray-100);
}

hr {
    border-width: .125rem;
    width: 100%;
}
