
/*$break-small: 640px;
$break-medium: 1024px;
$break-large: 1200px;

@mixin respond-to($media) {
    @if $media == small-screens {
        @media only screen and (max-width: $break-small) {
            @content;
        }
    }

    @else if $media == medium-screens {
        @media only screen and (min-width: $break-small + .0625) and (max-width: $break-medium + .0625) {
            @content;
        }
    }

    @else if $media == large-screens {
        @media only screen and (min-width: $break-medium + .0625) {
            @content;
        }
    }
}*/


@mixin respond-to($media) {
    @if $media == xs-screen {
        @media only screen and (max-width: 640px - 1px) {
            /*Screens < 40rem wide*/
            @content;
        }
    }


    @else if $media == sm-screen-up {
        @media only screen and (min-width: 640px) {
            /*Screens >= 40rem wide*/
            @content;
        }
    }
    @else if $media == sm-screen {
        @media only screen and (min-width: 640px) and (max-width: 768px - 1px) {
            /*Screens >= 40rem && < 48rem wide*/
            @content;
        }
    }
    @else if $media == sm-screen-down {
        @media only screen and (max-width: 768px - 1px) {
            /*Screens < 48rem wide*/
            @content;
        }
    }


    @else if $media == md-screen-up {
        @media only screen and (min-width: 768px) {
            /*Screens >= 48rem wide*/
            @content;
        }
    }
    @else if $media == md-screen {
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            /*Screens >= 48rem && < 64rem wide*/
            @content;
        }
    }
    @else if $media == md-screen-down {
        @media only screen and (max-width: 1024px) {
            /*Screens < 64rem wide*/
            @content;
        }
    }


    @else if $media == lg-screen-up {
        @media only screen and (min-width: 1024px + 1px) {
            /*Screens >= 64rem wide*/
            @content;
        }
    }
    @else if $media == lg-screen {
        @media only screen and (min-width: 1024px + 1px) and (max-width: 1200px - 1px) {
            /*Screens >= 64rem && < 75rem wide*/
            @content;
        }
    }
    @else if $media == lg-screen-down {
        @media only screen and (max-width: 1200px - 1px) {
            /*Screens < 75rem wide*/
            @content;
        }
    }


    @else if $media == xl-screen-up {
        @media only screen and (min-width: 1200px) {
            /*Screens >= 75rem wide*/
            @content;
        }
    }
    @else if $media == xl-screen {
        @media only screen and (min-width: 1200px) and (max-width: 1440px - 1px) {
            /*Screens >= 75rem && < 90rem wide*/
            @content;
        }
    }
    @else if $media == xl-screen-down {
        @media only screen and (max-width: 1440px - 1px) {
            /*Screens < 90rem wide*/
            @content;
        }
    }


    @else if $media == xxl-screen-up {
        @media only screen and (min-width: 1440px) {
            /*Screens >= 90rem wide*/
            @content;
        }
    }
    @else if $media == xxl-screen {
        @media only screen and (min-width: 1440px) and (max-width: 1760px - 1px) {
            /*Screens >= 90rem && < 110rem wide*/
            @content;
        }
    }
    @else if $media == xxl-screen-down {
        @media only screen and (max-width: 1760px - 1px) {
            /*Screens < 110rem wide*/
            @content;
        }
    }


    @else if $media == full-screen {
        @media only screen and (min-width: 1760px) {
            /*Screens >= 110rem wide*/
            @content;
        }
    }


    @else if $media == print {
        @media print {
            /*Print*/
            @content;
        }
    }
}
