/* //////////////////////////////////////////////////////////////////////////////// */
/* Global Contest Styles */
/* //////////////////////////////////////////////////////////////////////////////// */
@use '@angular/material' as mat;

.mdc-linear-progress__bar-inner {
    --mdc-linear-progress-active-indicator-color: black;
}
// Vendor
@import "./styles/resets";
@import "./styles/vendors";

// Defaults
@import "./styles/variables";
@import "./styles/animations";
@import "./styles/breakpoints";
@import "./styles/fonts";

@import "./styles/base";

// Typography
@import "./styles/headings";
@import "./styles/paragraphs";

// General Components
@import "./styles/buttons";
@import "./styles/modals";
@import "./styles/recaptcha";
@import "./styles/videos";

// Forms
@import "./styles/forms";
@import "./styles/checkboxes";

// Layouts
@import "./styles/main";
@import "./styles/footer";

// Utilities
@import "./styles/utilies";










/* ================================================================================ */
/* Contest Quiz */
/* ================================================================================ */

.logo h1 {
    display: inline-block;
    line-height: 1.55;
    color: var(--light-gray-100);
    /*margin-top: .75rem;*/
}

.intro img {
    float: right;
    margin: 0 0 1rem 1rem;
    width: 200px;

    @include respond-to(md-screen-up) {
        display: block;
    }
}

quiz img {
    width: 100%;
    max-width: 43.75rem;
}

.rules a {
    font-size: .875rem;
}

#options {
    list-style: none;
    padding: 0;
    margin: 0;
}

#options li {
    margin-bottom: 1rem;

    // font-size: 1.2em;
    // cursor: pointer;
    // border: 1px solid var(--light-gray-700);
}
