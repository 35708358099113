
:where(html) {
    --transparent: transparent;

    --white: #ffffff;
    --black: #000000;

    --light-gray-100: #FFFFFF;
    --light-gray-200: #FCFCFC;
    --light-gray-300: #FAFAFA;
    --light-gray-400: #F5F5F5; // #F2F4F3
    --light-gray-500: #F0F0F2; // #efeff0
    --light-gray-600: #E8E9EB; // #ebebeb, #e6e6e6
    --light-gray-700: #E1E2E6; // #e0e0e0, #e2e2e2
    --light-gray-800: #D9DBDE; // #dddddd, #dbdbdb
    --light-gray-900: #D2D3D6; // #cccccc, #d4d4d4

    --medium-gray-100: #B9BABD;
    --medium-gray-200: #AFB0B3;
    --medium-gray-300: #A5A7A8;
    --medium-gray-400: #97989C; // #a09b9b, #969696, #989898
    --medium-gray-500: #8D8E91;
    --medium-gray-600: #808185;
    --medium-gray-700: #79797D;
    --medium-gray-800: #727275;
    --medium-gray-900: #67686B;

    --dark-gray-100: #4E4E52;
    --dark-gray-200: #444447;
    --dark-gray-300: #3A3B3D;
    --dark-gray-400: #303133;
    --dark-gray-500: #272829;
    --dark-gray-600: #1C1D1F; // #222222
    --dark-gray-700: #131314; // #101011, #0e0f17
    --dark-gray-800: #09090A;
    --dark-gray-900: #000000;



    --medium-red-100: tomato;
    --medium-red-200: #E12B2A;
    --medium-red-300: #A31110;
    --medium-red-400: #90191c;
    --medium-red-500: #80030e;

    --green-100: #2bca69;
    --green-200: #36f17d;
    --green-300: #8abd3f;
    --green-400: #419A5D;
    --green-500: #4b7b38;
    --green-600: #517b25;
    --green-700: #506055;

    --medium-teal-200: #88C3BA;
    --medium-teal-800: #087C6A;

    --light-blue-900: #69C8FF;
    --medium-blue-100: #008CBA;
    --medium-blue-200: #007095;
    --medium-blue-300: #1779ba;
    --medium-blue-400: #156da9;

    --dark-blue-500: #0052A3;



    --brand-theme-100: #FFFFFF;
    --brand-theme-200: #FCFCFC;
    --brand-theme-300: #FAFAFA;
    --brand-theme-400: #F5F5F5;
    --brand-theme-500: #F0F0F2;
    --brand-theme-600: #E8E9EB;
    --brand-theme-700: #E1E2E6;
    --brand-theme-800: #D9DBDE;
    --brand-theme-900: #D2D3D6;

    --anf-gold: #FFD51D;
    --gold-bright: #FFD800;
    --isu-gold: #F1BE48;
    --gold-dark: #E7AE38;

    --youbetcha-blue-100: #a2dce6;
    --youbetcha-blue-200: #434e64;

    --info-color: dodgerblue;
    --success-color: green;
    --warning-color: gold;
    --alert-color: tomato;
    --critical-color: red;

    --size-000: 1px;
    --size-100: .125rem;
    --size-200: .25rem;
    --size-300: .5rem;
    --size-400: .75rem;
    --size-500: .875rem;
    --size-600: 1rem;
    --size-700: 1.25rem;
    --size-800: 1.5rem;
    --size-900: 2rem;
    --size-1000: 2.5rem;
    --size-1100: 3rem;

    --fs-tiny: var(--size-300);
    --fs-fine: var(--size-400);
    --fs-small: var(--size-500);
    --fs-default: var(--size-600);
    --fs-medium: var(--size-700);
    --fs-big: var(--size-800);
    --fs-large: var(--size-900);
    --fs-huge: var(--size-1000);
    --fs-display: var(--size-1100);

    --font-weight-100: 100;
    --font-weight-200: 200;
    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;

    --fw-thin: var(--font-weight-100);
    --fw-extralight: var(--font-weight-200);
    --fw-light: var(--font-weight-300);
    --fw-regular: var(--font-weight-400);
    --fw-medium: var(--font-weight-500);
    --fw-semibold: var(--font-weight-600);
    --fw-bold: var(--font-weight-700);
    --fw-extrabold: var(--font-weight-800);
    --fw-black: var(--font-weight-900);

    --font-family-100: 'Open Sans', sans-serif;
    --font-family-200: 'Roboto', sans-serif;
    --font-family-300: 'PT Sans', sans-serif;
    --font-family-400: 'Whitney', sans-serif;

    --icon-family-100: 'Font Awesome 6 Pro';

    --default-text-color: var(--dark-gray-700);
    --link-text-color: var(--dark-blue-500);

    --primary-color: var(--medium-blue-300);
    --secondary-color: var(--medium-blue-100);
    --error-color: var(--alert-color);
}
