

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: var(--font-family-400);
    font-weight: var(--fw-bold);
    word-break: break-word;
    text-wrap: balance;
}

h1, .h1 {
    color: var(--default-text-color);
}

h2, .h2 {
    font-family: var(--font-family-100);
    font-size: 1.2rem;
}

.overline {
    display: block;
    font-weight: var(--fw-regular);
    font-size: .875rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
}
