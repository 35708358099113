

.margin-reset {
    margin: unset;
}

.nopadding {
    padding: 0;
}

.no-margin {
    margin: 0;
}
