
form {
    display: grid;
    gap: 1rem;
}


label {
    display: block;
    font-size: .875rem;
    color: gray;
    margin-block-end: .25rem;
    line-height: 1;
}

[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
textarea,
select {
    margin: unset;
    border-radius: .25rem;
    border-width: .125rem;
    height: unset;
    box-shadow: 0 0 0 rgba(0 0 0 / 0%);
    border-color: transparent;
    background-color: rgba(0 0 0 / .05);

    &:hover, &:focus-visible, &:focus {
        border-width: .125rem;
    }

    &:hover {
        border-color: var(--dark-gray-100);
        box-shadow: 0 0 0 rgba(0 0 0 / 0%);
    }

    &:focus-visible {
        /*outline: .25rem solid var(--info-color);*/
        box-shadow: 0 0 0 rgba(0 0 0 / 0%);
    }

    &:focus {
        border-color: var(--info-color);
        box-shadow: 0 0 1rem var(--info-color);
    }
}

select:not([multiple]) {
    padding-block: .5rem;
}

.form-group {
    position: relative;

    &.checkbox {
        display: inline-flex;
        gap: .5rem;

        label {
            margin-inline: 0;
        }

        input {
            margin: unset;
        }
    }

    &:has(button+span) {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
    }
}

.form-error {
    display: block;
    line-height: 1.2;
    margin-block: unset;
    margin-block-start: 0.25rem;
}

.input-group {
    margin: unset;

    > :first-child {
        border-radius: .25rem 0 0 .25rem;

        &.input-group-button {
            > * {
                border-radius: .25rem 0 0 .25rem;
            }
        }
    }

    > :last-child {
        border-radius: 0 .25rem .25rem 0;

        &.input-group-button {
            > * {
                border-radius: 0 .25rem .25rem 0;
            }
        }
    }
}

.help-text {
    color: #68696B;
}
