
.footer {
    text-align: center;
    padding: 1rem;
    background-color: var(--light-gray-500);

    app-root:has(.contest-banner) + & {
        padding: 1rem 1rem 2rem 1rem;
    }
}

.footer-copyright {
    font-size: .875rem;
    margin: 0;
}
