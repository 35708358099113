/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300');*/
/*
@font-face {
    font-family: 'Whitney';
    src: url('/farmstrong/assets/fonts/whitney-black-webfont.eot');
    src: url('/farmstrong/assets/fonts/whitney-black-webfont.eot?#iefix') format('embedded-opentype'),
        url('/farmstrong/assets/fonts/whitney-black-webfont.woff') format('woff'),
        url('/farmstrong/assets/fonts/whitney-black-webfont.ttf') format('truetype'),
        url('/farmstrong/assets/fonts/whitney-black-webfont.svg#whitney-blackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
*/
