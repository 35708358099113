html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    --body-font-family: var(--font-family-100);
    --body-text-color: var(--default-text-color);
    --body-bg-image: unset;
    --body-bg-color: var(--light-gray-100);
    --body-bg-size: cover;
    --body-bg-position: center;

    font-family: var(--body-font-family);
    font-size: 1rem;
    font-weight: var(--fw-regular);
    color: var(--body-text-color);

    background-color: var(--body-bg-color);
    background-image: var(--body-bg-image);
    background-repeat: no-repeat;
    background-position: var(--body-bg-position);
    background-size: var(--body-bg-size);

    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;

    overflow-x: hidden;
    overflow-y: scroll;

    &.noScroll {
        overflow: hidden;
    }
}

app-root {
    display: grid;
    grid-template-rows: auto 1fr auto;
}
