.modal-container {
    position: fixed;
    inset: 0;
    z-index: 1000;
    isolation: isolate;
}

.modal {
    display: grid;
    gap: 2rem;
    max-width: 40rem;
    margin: 2rem auto;
    padding: 2rem;
    border: none;
    border-radius: .25rem;
    background-color: var(--light-gray-100);
    box-shadow: 0 1rem 1rem rgba(0 0 0 / .16), 0 .125rem .25rem rgba(0 0 0 / .08);
}

.modal-background {
    position: absolute;
    inset: 0;
    background-color: rgba(0 0 0 / .75);
    backdrop-filter: blur(.125rem);
    z-index: -1;
}

/*.reveal-modal {
    min-height: 1px;
    top: 30% !important;
    position: fixed;

    .close-reveal-modal {
        top: 0;
        right: .5rem;
    }
}

.reveal-modal-bg {
    z-index: 6000;
}*/
